import { ColorIcon, colors, HStack, NextLink, VStack, customEvent } from "goi_common";
import { NAVIGATOR_HEIGHT } from "@/constants/components";
import { css } from "@emotion/react";
import { useActivePathCheck } from "@/hooks/useActivePathCheck";
import { useQuickCallModal } from "@/hooks/useQuickCallModal";

import { useRouter } from "next/router";
import { NavProps } from "../Navigator";

export default function HomeNavigator() {
  const { activePath } = useActivePathCheck();
  const { onQuickCallModal } = useQuickCallModal();

  const router = useRouter();

  const elements: NavProps[] = [
    {
      iconName: "icon-home-mono",
      activeCheck: "홈",
      text: "홈",
      to: "/",
      eventDetails: ["home-uv_home"],
    },
    {
      iconName: "icon-stack-up-square-mono",
      activeCheck: "고이의 추천",
      text: "고이의 추천",
      to: "/match/recommendations/",
      eventDetails: ["mtch_rcmms"],
    },

    {
      iconName: "notepad",
      activeCheck: "상주분들의 후기",
      text: "상주분들의 후기",
      to: "/review/",
      eventDetails: ["review-uv_review"],
    },
    {
      iconName: "icon-call-mono",
      activeCheck: "전화 상담",
      text: "전화 상담",
      to: router.asPath + "#none",
      eventDetails: ["call-opn_call_mdl"],
      onClick: () => {
        onQuickCallModal();
      },
    },
  ];

  return (
    <VStack width="100%" alignItems="center">
      <>
        <HStack height={NAVIGATOR_HEIGHT} className="body3_m" css={navCss}>
          {elements.map((e, index) => {
            return (
              <NextLink
                css={{
                  width: "calc(100%/4)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: e.activeCheck === "전화 상담" ? colors.green50 : "",
                }}
                href={e.to}
                key={index}
              >
                <VStack
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  css={{ cursor: "pointer" }}
                  onClick={() => {
                    if (e.onClick) {
                      e.onClick();
                    }
                    e.eventDetails.map((res) => {
                      customEvent({
                        newGtm: {
                          path: "gnb",
                          trigger_and_target: res,
                        },
                      });
                    });
                  }}
                >
                  <ColorIcon
                    name={e.iconName}
                    size={27}
                    color={
                      e.activeCheck === "전화 상담"
                        ? colors.green500
                        : activePath === e.activeCheck
                        ? colors.gray900
                        : colors.gray300
                    }
                  />
                  <div
                    className="caption1"
                    css={{
                      color:
                        e.activeCheck === "전화 상담"
                          ? colors.green500
                          : activePath === e.activeCheck
                          ? colors.gray900
                          : colors.gray300,
                    }}
                  >
                    {e.text}
                  </div>
                </VStack>
              </NextLink>
            );
          })}
        </HStack>
      </>
    </VStack>
  );
}

const navCss = css({
  position: "fixed",
  width: "100%",
  maxWidth: "480px",
  bottom: "0",
  backgroundColor: `${colors.white}`,
  zIndex: "1060",
  borderTop: `1px solid ${colors.gray100}`,
  userSelect: "none",
});
