import { useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors, getCdnImageSrc, NextLink, useResponsive, VStack } from "goi_common";
import Menu from "../Menu";
import HomeHeader from "./HomeHeader";
import HomeNavigator from "./HomeNavigator";

export default function HomeLayout({ children }: { children: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isBiggerThanMd } = useResponsive();

  return (
    <>
      <BackgroundImage src={getCdnImageSrc("images/global_background.jpg")} />
      <GlobalContainer>
        {isBiggerThanMd && (
          <VStack width={210} gap={16} justifyContent="center">
            <div style={{ lineHeight: "18px" }} className="head1_sb">
              장례의 모든 것
            </div>
            <div style={{ padding: "0 0 16px" }} className="head1_sb font-700">
              고이
            </div>
            <NextLink
              href="/match/request/"
              event={{ newGtm: { path: "home", trigger_and_target: "layout_CTA-uv_mtch_rqst" } }}
            >
              <VStack
                css={{
                  width: 150,
                  height: 60,
                  backgroundColor: colors.black,
                  borderRadius: 10,
                  color: colors.white,
                }}
                className="subtitle_18_b"
                justifyContent="center"
                alignItems="center"
              >
                총 비용 알아보기
              </VStack>
            </NextLink>
          </VStack>
        )}
        <GlobalWrapper style={{ width: "var(--global-max-width)" }}>
          <HomeHeader onOpen={onOpen} />
          <VStack>{children}</VStack>
          <HomeNavigator />
          <Menu isOpen={isOpen} onClose={onClose} />
        </GlobalWrapper>
      </GlobalContainer>
    </>
  );
}

const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.2;
`;

export const GlobalContainer = styled.div`
  display: flex;
  height: calc(var(--vh) * 100);
  justify-content: center;
`;

export const GlobalWrapper = styled.div`
  position: relative;

  min-height: calc(var(--vh) * 100);
  background-color: var(--white);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
`;
