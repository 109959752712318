import { isViewportTopState } from "@/atoms/viewportTopState";
import { HEADER_HEIGHT } from "@/constants/components";
import {
  colors,
  customEvent,
  GoiLogo,
  Hamburger,
  HStack,
  NextLink,
  Spacing,
  VStack,
} from "goi_common";
import React from "react";
import { useRecoilValue } from "recoil";
import { HeaderButtons } from "../Header";
import { css } from "@emotion/react";
import { Box } from "@chakra-ui/react";

export default function HomeHeader({ onOpen }: { onOpen: () => void }) {
  const isViewportTop = useRecoilValue(isViewportTopState);

  const handleHamburgerClick = () => {
    customEvent({
      newGtm: {
        path: "gnb",
        trigger_and_target: "hamburger",
      },
    });
    onOpen();
  };
  return (
    <header>
      <VStack width="100%" alignItems="center">
        <HStack height={HEADER_HEIGHT} css={headerCss({ isViewportTop })}>
          <HStack
            padding="0px 20px"
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <NextLink
              id="goi-logo"
              href={"/"}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "logo-uv_home",
                  },
                });
              }}
            >
              <GoiLogo color={isViewportTop ? "white" : "black"} />
            </NextLink>
            <HStack alignItems="center">
              <Spacing orientation="horizontal" gap={36} />
              <HeaderButtons isHomeViewportTop={isViewportTop} homePath={"/"} />

              <Spacing orientation="horizontal" gap={16} />
              <Box position="relative">
                <button onClick={handleHamburgerClick}>
                  <Hamburger color={isViewportTop ? colors.white : colors.gray700} />
                </button>
                <Box
                  position="absolute"
                  className="white"
                  width="12px"
                  height="12px"
                  borderRadius="50%"
                  background="red"
                  fontSize="8px"
                  textAlign="center"
                  verticalAlign="middle"
                  lineHeight="12px"
                  top="-5px"
                  right="-10px"
                >
                  N
                </Box>
              </Box>
            </HStack>
          </HStack>
        </HStack>
      </VStack>
    </header>
  );
}

const headerCss = ({ isViewportTop }: { isViewportTop: boolean }) =>
  css({
    borderBottom: `1px solid ${isViewportTop ? colors.gray300 : colors.gray100}`,
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    position: "fixed",
    maxWidth: "480px",
    width: "100%",
    top: "0",
    backgroundColor: isViewportTop ? `transparent` : colors.white,
    transition: isViewportTop ? "background-color 0.1s linear" : "none",
    zIndex: "1000",
    userSelect: "none",
  });
