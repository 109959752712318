import { setVisitedFeature, VStack } from "goi_common";
import useIntersectionObserver from "goi_common/src/hooks/useIntersectionObserver";
import { useEffect, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { isViewportTopState } from "../../atoms/viewportTopState";
import { useRouter } from "next/router";
import { scrollYState } from "./atoms/scrollYState";

import LazyLoad from "goi_common/src/components/LazyLoad";
import { homLazyLoadFirstState } from "./atoms/homeLazyLoadFirstState";

import { HeroSection } from "./components/RenewalVersion/HeroSection";
import { GoiService } from "./components/GoiService";
import { SelectedAndReviewAndGuideSection } from "./components/RenewalVersion/SelectedAndReviewAndGuideSection";
import { StandBy24Hours } from "./components/StandBy24Hours";
import GoiMessage from "./components/GoiMessage";
import RenewalFooter from "./components/RenewalFooter";
import HomeLayout from "@/components/Layout/HomeLayout/HomeLayout";
import HomeStepGuideFooter from "./components/RenewalVersion/HomeStepGuideFooter";

export default function HomeContainer({ name }: { name: string }) {
  const [isHomeLazyLoadFirst, setIsHomeLazyLoadFirst] = useRecoilState(homLazyLoadFirstState);
  useEffect(() => {
    setVisitedFeature("home");
    localStorage.setItem("home-router", "");
  }, []);

  const viewportTopRef = useRef<HTMLDivElement>(null);
  const setIsViewportTop = useSetRecoilState(isViewportTopState);

  useIntersectionObserver({
    root: null,
    target: viewportTopRef,
    onIntersect: () => {
      setIsViewportTop(true);
    },
    onNotIntersect: () => {
      setIsViewportTop(false);
    },
    rootMargin: "30px 0px",
  });

  const router = useRouter();
  const [scrollYPostion, setScrollYPosition] = useRecoilState(scrollYState);

  const handleSetScrollY = () => {
    setScrollYPosition(window.scrollY);
  };

  useEffect(() => {
    window.scrollTo(0, scrollYPostion);
    setScrollYPosition(0);
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", handleSetScrollY);
    return () => {
      router.events.off("routeChangeStart", handleSetScrollY);
    };
  }, []);

  return (
    <HomeLayout>
      <VStack maxWidth="480px" background="white" width="100%" alignItems="center">
        <HomeStepGuideFooter
          iconMarginLeft="30px"
          bottom={"80px"}
          right={"0px"}
          event={{ newGtm: { path: "home", trigger_and_target: "step_guide_fixed_button" } }}
        />
        <div ref={viewportTopRef}></div>
        <HeroSection />
        <SelectedAndReviewAndGuideSection />

        <LazyLoad
          width="100%"
          // alignItems="center"
          hasCustomState
          customVisibleState={isHomeLazyLoadFirst}
          setCustomVisibleState={setIsHomeLazyLoadFirst}
          top={-1250}
        >
          <GoiService />
          <StandBy24Hours />
          <GoiMessage />
        </LazyLoad>
        <RenewalFooter />
      </VStack>
    </HomeLayout>
  );
}
