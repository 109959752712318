import RichSearch from "@/components/common/RichSearch";
import { 후기_리치서치_컨텐츠들, 후기_리치서치_컨텐츠_개수들 } from "@/constants/richSearch/review";
import { HomeContainer } from "@/containers/HomeContainer";
import {
  ReviewOmitContentType,
  getReviewRichSearch,
  AggregateRatingVariable,
  getAggregateRating,
} from "@/utils/richSearch";

import Head from "next/head";

export async function getStaticProps() {
  return {
    props: {
      pageOgTitle: "장례의 모든 것, 고이",
    },
  };
}

function HomePage({ pageOgTitle }: { pageOgTitle: string }) {
  const reviewRichSearchData: ReviewOmitContentType[] = 후기_리치서치_컨텐츠들.전체.map(
    (reviewBody, index) => ({
      reviewBody,
      author: {
        "@type": "Person",
        name: "상주" + index,
      },
    }),
  );

  const reviewRichSearch = getReviewRichSearch(pageOgTitle, reviewRichSearchData);
  const aggregateRatingVariable: AggregateRatingVariable = {
    name: pageOgTitle,
    aggregateRating: {
      "@type": "AggregateRating",
      bestRating: "5",
      ratingCount: 후기_리치서치_컨텐츠_개수들.전체,
      ratingValue: "4.97",
      reviewCount: 후기_리치서치_컨텐츠_개수들.전체,
    },
  };
  const aggregateRating = getAggregateRating("Product", aggregateRatingVariable);

  const aggregateRatingAndReview = { ...aggregateRating, ...reviewRichSearch };

  return (
    <>
      <RichSearch data={aggregateRatingAndReview} />
      <RichSearch
        data={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "고이장례연구소",
          alternateName: "장례의 모든 것, 고이",
          url: "https://www.goifuneral.co.kr/",
        }}
      />

      <Head>
        <meta property="og:url" content="https://www.goifuneral.co.kr/" />
        <link rel="canonical" href="https://www.goifuneral.co.kr/" key="canonical" />
      </Head>
      <HomeContainer name="goi" />
    </>
  );
}

export default HomePage;
